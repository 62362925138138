.btn-buy {
  border: solid 0px;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 0 10px;
}

#topMenu {
  background: rgb(33, 206, 153);
  height: 20px;
  padding: 20px 0;
  position: fixed;
  width: 100%;
}

.isSupported {
  position: absolute;
  right: 0;
  width: 50%;
  color: #ccc;
  margin: 14px 20px;
  pointer-events: none;
}

.btn-buy a {
  text-decoration: none;
}

#ChartPortfolioValue {
  background: gray;
  height: 140px;
  margin: 10px 10px 15px;
}

.Pie {
  background-color: #303032;
  margin: -25px 10px;
}
.toggleView {
  color: white;
  position: absolute;
  right: 11px;
  top: 123px;
  cursor: pointer;
}
body {
  background-color: black;
  font-family: Roboto, sans-serif;
}

.lightGray {
  color: hsla(0,0%,100%,.25);
}
.green {
  color: #21ce99;
}

.red {
  color: rgb(216, 45, 45);
}

.white {
  color: white;
}
.center {
  text-align: center;
}

a {
  color: white;
}

.theMenu {
  background-color: #303032;
  height: 100vh;
  padding-top: 20px;
}

.menu {
  margin-left: 20px;
}

.Home .header {
  background-color: #21ce99;
  padding-top: 20px;
  padding-bottom: 20px;
}

.CoinList {
  height: 100%;
  margin: -25px 10px;
}

.listCoin {
  background: #303032;
  border-bottom: 1px solid hsla(0,0%,100%,.1);
  padding: 20px;

}
.listCoin:after {
  content: '';
  display: block;
  clear: both;
}

.coinLink {
  text-decoration: none;
}

.left {
  float: left;
  text-align: left;
}
.middle {
  position: absolute;
  right: 0;
  margin: 10px 50vw;
}
.right {
  float: right;
  text-align: right;
}
.right:after {
  content: '';
  display: block;
  clear: both;
}

.Coin {
  background-color: #21ce99;
  height: 100vh;
  margin-top: -15px;
  padding-top: 35px;
}

.coinClose {
  float: right;
  padding-right: 20px;
}

#chart_container {
  min-height: 200px;
}

.coinCard {
  background-color: #303032;
  color: white;
  text-align: center;
  margin-top: 20px;
  padding-top: 10px;
  min-height: calc(100vh - 60px);
}
.coinCard h1, .coinCard h2 {
  font-weight: 300;
  margin: 0;
}
.coinCard h1 {
  font-size: 2.5rem;
  margin-top: 5px;
}
.coinCard h2 {
  margin-top: 10px;
}

.addFirstCoin {
  text-align: center;
  color: white;
  background-color: #303032;
  padding: 10px 20px 0 20px;
}

.addFirstCoin:after {
  content: "";
  display: table;
  clear: both;
}

select {
  height: 40px;
  padding: 10px;
  margin-bottom: 5px;
  background-color: white;
}
input {
  width: 50%;
  margin: 5px;
  padding: 10px;
}
.btn {
  background-color: #fafafa;
  color: #000;
  padding: 15px;
}

.Blockstack {
  background-color: #21ce99;
  height: 100vh;
  text-align: center;
}
.Blockstack .menu {
  float: left;
}
.Blockstack .menu:after {
  content: '';
  display: block;
  clear: both;
}
.block-login {
  padding-top: 35vh;
}
#login {
  cursor: pointer;
}
#logout {
  float: right;
  color: white;
  margin: 20px;
  margin-bottom: 0;
}

.fa-trash {
  cursor: pointer;
  font-size: 1.25rem;
}
.trash {
  border-bottom: none;
}
